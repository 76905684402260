// Declaring type of props - see "Typing Component Props" for more examples
type TweetProps = {
  name: string;
  link: string;
  className?: string;
}; /* use `interface` if exporting so that consumers can extend */

// Easiest way to declare a Function Component; return type is inferred.
const TweetLinkComponent = ({ name, link, className }: TweetProps) => {
  return (
    <a href={link} className={className} target={"_blank"} rel="noreferrer">
      {name}
    </a>
  );
};

export default TweetLinkComponent;
