import TweetSingleHashComponent from "./tweet-single-hash-component/tweet-single-hash-component";
import "./tweet-single-component.css";
import TweetSingleMediasComponent from "./tweet-single-medias-component copy";
import { useEffect, useState } from "react";
import TweetLinkComponent from "../tweet-single-link-component";
import { SocialIcon } from "react-social-icons";
import s3Logo from "../../../images/s3-logo.png";
import { FaDownload } from "react-icons/fa/";

// Declaring type of props - see "Typing Component Props" for more examples
type TweetProps = {
  tweet: TweetSingle;
}; /* use `interface` if exporting so that consumers can extend */

export interface TweetSingle {
  hashtags: string;
  id: string;
  medias: TweetMedia[];
  post_date: string;
  tweet_text: string;
  tweet_url: string;
  user_id: string;
  username: string;
}

export interface TweetMedia {
  download_link: string;
  id: string;
  media_duraration: number;
  media_filename: string;
  media_format: string;
  media_hash: string;
  media_type: string;
  media_views: number;
  tweet_id: string;
}

// Easiest way to declare a Function Component; return type is inferred.
const TweetSingleComponent = ({ tweet }: TweetProps) => {
  const [tweetURL, setTweetURL] = useState("");
  const [s3URL] = useState("https://iran-javanan.s3.amazonaws.com/");
  const [username, setUsername] = useState("");
  const [mediaDownloadLinks, setMediaDownloadLinks] = useState<string[]>([]);

  useEffect(() => {
    setTweetURL(`https://twitter.com/${tweet.username}`);
    setUsername(`@${tweet.username}`);
  }, [tweet, setTweetURL, setUsername]);

  return (
    <div className="tweet-single">
      <div className="flex-row">
        <TweetLinkComponent
          name={username}
          link={tweetURL}
          className={"user-link"}
        />
        <SocialIcon url={tweet.tweet_url} target={"_blank"} rel="noreferrer" />
      </div>
      <TweetSingleMediasComponent
        medias={tweet.medias}
        setMediaDownloadLinks={setMediaDownloadLinks}
      />
      <div className={"tweet-text"}>{tweet.tweet_text}</div>
      <TweetSingleHashComponent
        hashtags={tweet.hashtags}
        className={"hashtag"}
      />
      <br></br>
      <div className="tweet-date">
        {tweet.post_date}
        <div style={{ display: "flex", flexGrow: 1 }} />
        <button
          onClick={() => {
            mediaDownloadLinks.forEach((m) => {
              window.open(m);
            });
          }}
          className="download"
        >
          <FaDownload />
        </button>
        <a href={s3URL} className={"s3link"} target={"_blank"} rel="noreferrer">
          <img className={"logo"} src={s3Logo} alt="s3" />
        </a>
      </div>
    </div>
  );
};

export default TweetSingleComponent;
