import TweetSingleComponent, {
  TweetSingle,
} from "./tweet-single-component/tweet-single-component";
import "./tweet-results-component.css";

export interface TweetResult {
  results: TweetSingle[];
}

// Easiest way to declare a Function Component; return type is inferred.
const TweetResults = ({ results }: TweetResult) => {
  const tweets = [];
  for (const tweet of results) {
    tweets.push(<TweetSingleComponent tweet={tweet} key={tweet.id} />);
  }
  console.log(tweets);
  return <div className="App">{tweets}</div>;
};
export default TweetResults;
