import { useState, useEffect, useCallback } from "react";
import { TweetSingle } from "../tweet-results-component/tweet-single-component/tweet-single-component";
import "./tweet-query-component.css";
import tweets from "../../tweets.json";

export interface TweetResult {
  setResults: (results: TweetSingle[]) => void;
}

// Easiest way to declare a Function Component; return type is inferred.
const TweetQuery = ({ setResults }: TweetResult) => {
  const [queryBeginDate, setQueryBeginDate] = useState("2022-08-01T00:00");
  const [queryEndDate, setQueryEndDate] = useState("2022-08-01T00:00");

  const submit = useCallback(() => {
    setResults(tweets.result);
    const bd = new Date(queryBeginDate);
    const ed = new Date(queryEndDate);
    console.log(bd.toISOString());
    console.log(ed.toISOString());
  }, [setResults, queryBeginDate, queryEndDate]);

  useEffect(() => {
    submit();
  }, [submit]);

  return (
    <div className="query">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submit();
        }}
      >
        <div className="query_begin_input_div">
          <label className="query_begin_input_label">{"Start date"}</label>
          <input
            className="query_begin_input_box"
            placeholder=" "
            type="datetime-local"
            value={queryBeginDate}
            onChange={(event) => {
              console.log(event.target.value);
              setQueryBeginDate(event.target.value);
            }}
          />
        </div>

        <div className="query_end_input_div">
          <label className="query_end_input_label">{"End date"}</label>
          <input
            className="query_end_input_box"
            placeholder=" "
            type="datetime-local"
            value={queryEndDate}
            onChange={(event) => {
              console.log(event.target.value);
              setQueryEndDate(event.target.value);
            }}
          />
        </div>

        <div className="query_end_input_div">
          <button className={`query_button`}>{"Search"}</button>
        </div>
      </form>
    </div>
  );
};
export default TweetQuery;

// const exampleJSON: TweetSingle[] = [
//   {
//     hashtags:
//       "Téhéran, Kurdistan, IranProtests, IranRevolution, Iran, Mahsa_Amini",
//     id: "8198DDAD4DF44353B446AE880F668E8F",
//     medias: [
//       {
//         download_link: "https://t.co/xBKZ74l62C",
//         id: "0F8CC87A7F0F4EDF9436839E28EDC223",
//         media_duraration: 18.274,
//         media_filename: "710C7570108F4F08B3D1722C1835BF87.mp4",
//         media_format: "mp4",
//         media_hash: "64e189bc826c142d4553d7e17ae39bdf",
//         media_type: "VIDEO",
//         media_views: 1,
//         tweet_id: "8198DDAD4DF44353B446AE880F668E8F",
//       },
//     ],
//     post_date: "Sat, 03 Dec 2022 22:14:34 GMT",
//     tweet_text:
//       "3 déc. #Téhéran- Ekbatan\nTéhéran devient #Kurdistan, l'Iran devient le Jardin des Fleurs #IranProtests #IranRevolution #Iran #Mahsa_Amini https://t.co/8VdHmsUFgz",
//     tweet_url: "https://t.co/osHYG12KZi",
//     user_id: "2185008332",
//     username: "parnian2013",
//   },
//   {
//     hashtags: "IranRevolution2022, Mahsa_Amini",
//     id: "2DB070248D2C4358B85CEC2D1BEDDD66",
//     medias: [
//       {
//         download_link: "https://t.co/5LnWkdekKL",
//         id: "7C653EB6ACC740BA82D8BD8FC61ADE9C",
//         media_duraration: 0.0,
//         media_filename: "13023756085D4619AF3B7EE1F757B119.jpg",
//         media_format: "jpg",
//         media_hash: "f6dd9bd3074889e0e85d608f0b67d0a2",
//         media_type: "PHOTO",
//         media_views: 0,
//         tweet_id: "2DB070248D2C4358B85CEC2D1BEDDD66",
//       },
//     ],
//     post_date: "Sat, 03 Dec 2022 22:14:26 GMT",
//     tweet_text:
//       "A handicapped Baluchi prisoner was sentenced to Moharebeh ( corruption on earth). Mansur Dehmardeh, 22, was arrested on October 11 in Zahedan and has been kept  in Zahedan prison since his arrest. He is at risk of execution. #IranRevolution2022 \n#Mahsa_Amini https://t.co/pfwAJbR8Hg",
//     tweet_url: "https://t.co/ttlMln5yEi",
//     user_id: "2348633696",
//     username: "ARAMalliance",
//   },
//   {
//     hashtags: "Mahsa_Amini, kian_pirfalak, Iran, aylarhaghi",
//     id: "697518905741445A81416C56B2FE3A4E",
//     medias: [
//       {
//         download_link: "https://t.co/TX2mRgfYr7",
//         id: "6414CD3041114DEE8B910332B1BBD38C",
//         media_duraration: 0.0,
//         media_filename: "D85597F0E277424D95DBFB5F6EABA672.jpg",
//         media_format: "jpg",
//         media_hash: "3ff9c3a3aef0b0c297a9565726f6ed9f",
//         media_type: "PHOTO",
//         media_views: 0,
//         tweet_id: "697518905741445A81416C56B2FE3A4E",
//       },
//     ],
//     post_date: "Sat, 03 Dec 2022 22:06:11 GMT",
//     tweet_text:
//       "Weisen Sie die Botschafter und Mitarbeiter des Regimes\naus EU aus.\n#Mahsa_Amini #kian_pirfalak #Iran #aylarhaghi https://t.co/FxV1nqXagh",
//     tweet_url: "https://t.co/MlYIEThv8N",
//     user_id: "1581711370554380289",
//     username: "StudioApfel",
//   },
// ];
