import { useState } from "react";
import "./App.css";
import TweetQuery from "./components/tweet-query-component";
import TweetResults from "./components/tweet-results-component";
import { TweetSingle } from "./components/tweet-results-component/tweet-single-component/tweet-single-component";

function App() {
  const [results, setResults] = useState<TweetSingle[]>([]);

  return (
    <div className={"main"}>
      <TweetQuery setResults={setResults}></TweetQuery>
      <TweetResults results={results}></TweetResults>
    </div>
  );
}

export default App;
